import Vue from 'vue'
import Vuex from 'vuex'
import { http } from '@/utils/http'
import organization from './modules/organization'
import users from './modules/users'
import snackbar from './modules/snackbar'
import router from '@/router';
import projects from './modules/projects'
import i18n from '@/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShow: true,
    isEnglish: false,
    user: {
      id: '',
      name: '',
      role: '',
      token: '',
      username: ''
    },
    data: {
      role: '',
      name: {
        type: 'name',
        value: '',
      },
      isStatus: null,
      email: {
        type: 'email',
        value: '',
      },
      username: {
        type: 'username',
        value: '',
      },
      phone: {
        type: 'phone',
        value: '',
      },
      phone_office: {
        type: 'phone_office',
        value: '',
      },
      organization: {
        type: 'organization',
        id: '',
        name: ''
      }
    },
  },
  getters: {
  },
  mutations: {
    setShow(state, payload) {
      state.isShow = payload
    },
    seti18n(state, payload) {
      state.isEnglish = payload
    },
    setUser(state, payload) {
      const userData = sessionStorage.getItem('user')
      const _user = JSON.parse(userData)
      state.user = _user
    },
    setUserClear(state, payload) {
      state.user = sessionStorage.clear();
    },
    setData(state, payload) {
      state.data.role = payload.role
      state.data.name.value = payload.name
      state.data.email.value = payload.email
      state.data.username.value = payload.username
      state.data.phone.value = payload.phone
      state.data.phone_office.value = payload.phone_office
      state.data.organization.id = payload.organization
      state.data.organization.name = payload._organization.name
    },
  },
  actions: {
    getUser(context, payload) {
      context.commit('setUser')
    },
    clearUser(context, payload) {
      context.commit('setUserClear')
      router.replace({ name: "login" }).catch(() => { });
    },
    async getData({ state, commit, dispatch }, payload) {
      const { data } = await http.get(`/user/query/${payload}`)
      if (data.status === false) {
        dispatch('snackbar/open_snackbar', {
          msg:  state.isEnglish === true ? data.msg_en : data.msg,
          color: 'error'
        }, { root: true })
        router.replace({ name: "login" })
      } else {
        commit('setData', data.data)
      }
    },
    async updateData({ dispatch }, payload) {
      console.log('payload', payload)
      const res = await http.post(`/user/update/${payload.id}`, { type: payload.data.type, value: payload.data.value })
      console.log(res)
      if (res.data.status === false) {
        dispatch('snackbar/open_snackbar', {
          msg: `${i18n.t('update.fail')}!`,
          color: 'error'
        }, { root: true })
      } else {
        dispatch('snackbar/open_snackbar', {
          msg: `${i18n.t('update.success')}!`,
          color: 'success'
        }, { root: true })
        dispatch('users/getList', {id: payload.id}, { root: true })
        dispatch('users/getAllList', null, { root: true })
      }
    },
    async firstPassword({ dispatch }, payload) {
      const res = await http.post(`/user/first/${this.state.user.id}`, {
        password_new: payload.new,
        password_new_again: payload.new
      })
      if (res.data.status === false) {
        dispatch('snackbar/open_snackbar', {
          msg: `${i18n.t('update.fail')}!`,
          color: 'error'
        }, { root: true })
      } else {
        dispatch('snackbar/open_snackbar', {
          msg: `${i18n.t('update.firstLoginMsg')}`,
          color: 'success'
        }, { root: true })
        setTimeout(() => {
          dispatch('clearUser')
          router.replace({ name: "login" }).catch(() => { });
        }, 1500);
      }
    },
    async putPassword({ dispatch }, payload) {
      const res = await http.post(`/user/update_password/${payload.id}`, {
        password: payload.password.old,
        password_new: payload.password.new,
        password_new_again: payload.password.new
      })
      if (res.data.status === false) {
        dispatch('snackbar/open_snackbar', {
          msg: res.data.msg,
          color: 'error'
        }, { root: true })
      } else {
        dispatch('snackbar/open_snackbar', {
          msg: `${i18n.t('update.firstLoginMsg')}`,
          color: 'success'
        }, { root: true })
        setTimeout(() => {
          dispatch('clearUser')
          router.replace({ name: "login" }).catch(() => { });
        }, 1500);
      }
    },

  },
  modules: {
    organization: organization,
    users: users,
    projects: projects,
    snackbar: snackbar,
  }
})
