<template>
  <v-app>
    <v-main>
      <router-view />
      <Snackbar></Snackbar>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from '@/components/Snackbar.vue'
export default {
  name: 'App',
  data: () => ({
    //
  }),
  components: {
    Snackbar
  },
};
</script>
<style lang="scss">
@import "./assets/style/common.scss";
</style>