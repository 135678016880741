import axios from 'axios'
import store from '@/store/index.js';



const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000,
})

//request
http.interceptors.request.use(
    config => {
        if (store.state.user.token) {
            config.headers.Authorization = store.state.user.token
        }
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)

//response
http.interceptors.response.use(
    response => {
        return response
    },
    error => {
        console.log(error)
        if (error.message === 'timeout of 10000ms exceeded') {
            // location.href = location.origin + "/"
            store.dispatch('snackbar/open_snackbar', {
                msg: 'timeout of 10000ms exceeded',
                color: 'error'
            }, { root: true })
        } else if (error.response.status == 401) {
            //token過期或沒傳 token 需要重新登入獲取token
            location.href = location.origin + "/"
        }
        return Promise.reject(error)
    }
)


export { http }