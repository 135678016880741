import { http } from '@/utils/http'
import router from '@/router';
import i18n from '@/i18n'


const state = {
    allData: [],
    data: {
        name: {
            value: '',
        },
        setTime: ''
    },
    selectUsers: [],
    selectProjects: []
};

const getters = {
};

const mutations = {
    setAllList(state, payload) {
        state.allData = payload
    },
    setList(state, payload) {
        state.data.name.value = payload.name
        state.data.setTime = payload._createDT
    },
    setUsersList(state, payload) {
        state.selectUsers = payload
    },
    setProjectsList(state, payload) {
        state.selectProjects = payload
    }
};

const actions = {
    async getAllList({ commit }) {
        const { data } = await http.get('/organization/query')
        commit('setAllList', data.data)
    },
    async getList({ commit, dispatch, rootState }, payload) {
        const { data } = await http.get(`/organization/query/${payload}`)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            commit('setList', data.data)
            dispatch('getProjects', { type: 'organizationid', val: payload })
            dispatch('getUsers', { type: 'organizationid', val: payload })
            router.push({ name: "organizationDetail", params: { id: payload } }).catch(() => { });
        }
    },
    // getUsers 
    async getUsers({ commit, dispatch, rootState }, payload) {
        let params = '?'
        if (payload.type === 'organizationid') {
            params += `organizationid=${payload.val}`
        }
        const { data } = await http.get(`/user/query${params}`)
        if (data.status === false  && rootState.user.role !== 'user') {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            commit('setUsersList', data.data)
        }
    },
    // getProjects 
    async getProjects({ commit, dispatch, rootState }, payload) {
        let params = '?'
        if (payload.type === 'organizationid') {
            params += `organizationid=${payload.val}`
            const { data } = await http.get(`/project/query${params}`)
            if (data.status === false) {
                dispatch('snackbar/open_snackbar', {
                    msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                    color: 'error'
                }, { root: true })
            } else {
                commit('setProjectsList', data.data)
            }
        }else{
            return
        }

    },

    async updateList({ dispatch }, payload) {
        const _data = {
            name: payload.editData.name.value,
        }
        const res = await http.post(`/organization/update/${payload.id}`, _data)
        if (res.data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('update.fail')}!`,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('update.success')}!`,
                color: 'success'
            }, { root: true })
        }
    },
    async saveList({ dispatch, rootState }, payload) {
        const { data } = await http.post(`/organization/add`, { name: payload.name })
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('save.success')}!`,
                color: 'success'
            }, { root: true })
            dispatch('getAllList')
        }
    },
    async deleteList({ dispatch, rootState }, payload) {
        const { data } = await http.post(`/organization/delete/${payload}`)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('deleteMsg.success')}!`,
                color: 'success'
            }, { root: true })
            dispatch('getAllList')
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};