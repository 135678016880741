import { http } from '@/utils/http'
import i18n from '@/i18n'
import router from '@/router';

const state = {
    allData: [],
    isReset: false,
    loading: false,
    data: {
        isStatus: null,
        name: {
            type: 'name',
            value: '',
        },
        email: {
            type: 'email',
            value: '',
        },
        username: {
            type: 'username',
            value: '',
        },
        phone: {
            type: 'phone',
            value: '',
        },
        phone_office: {
            type: 'phone_office',
            value: '',
        },
        organization: {
            type: 'organization',
            value: '',
            items: [
                {
                    name: '',
                    id: ''
                }

            ],
        },
    },
    items: [],
    history: []
};

const getters = {
};

const mutations = {
    setAllList(state, payload) {
        payload.forEach((item) => {
            if (item.role === 'admin' || item.role === 'superadmin') {
                item.isAdmin = true
            } else {
                item.isAdmin = false
            }
            if (item.organization === item._organization.id) {
                item.organizationName = item._organization.name
            }
        });
        state.allData = payload
    },
    setList(state, payload) {
        state.data.name.value = payload.name
        state.data.email.value = payload.email
        state.data.username.value = payload.username
        state.data.phone.value = payload.phone
        state.data.phone_office.value = payload.phone_office
        state.data.organization.value = payload.organization
        state.data.role = payload.role
        if (payload.role === 'admin' || payload.role === 'superadmin') {
            state.data.isStatus = true
        } else {
            state.data.isStatus = false
        }
    },
    setIsReset(state, payload) {
        state.isReset = payload
    },
    setOrganizationList(state, payload) {
        let data = []
        payload.forEach((item) => {
            let obj = {}
            obj.name = item.name
            obj.id = item.id
            data.push(obj)
        })
        state.data.organization.items = data
        state.items = data
    },
    setHistory(state, payload) {
        state.history = payload
    },
    setLoading(state, payload) {
        state.loading = payload
    },
};

const actions = {
    async search({ commit, dispatch }, payload) {
        let params = '?'
        if (payload.type === 'name') {
            params += `name=${payload.val}`
        } else if (payload.type === 'organizationName') {
            params += `organization=${payload.val}`
        } else if (payload.type === 'username') {
            params += `username=${payload.val}`
        } else if (payload.type === 'phone') {
            params += `phone=${payload.val}`
        }
        const { data } = await http.get(`/user/query${params}`)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('search.msgError')}!`,
                color: 'error'
            }, { root: true })
        } else {
            commit('setAllList', data.data)
        }
    },
    async getAllList({ commit }) {
        const { data } = await http.get('/user/query')
        commit('setAllList', data.data)
    },
    async getList({ commit, dispatch, rootState }, payload) {
        const { data } = await http.get(`/user/query/${payload.id}`)
        commit('setLoading', true)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            commit('setList', data.data)
            dispatch('getOrganizationList')
            router.push({ name: "userDetail", params: { id: payload.id } }).catch(() => { });
            commit('setLoading', false)
        }
    },
    async updateList({ dispatch }, payload) {
        const res = await http.post(`/user/update/${payload.id}`, { type: payload.data.type, value: payload.data.value })
        if (res.data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('update.fail')}!`,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('update.success')}!`,
                color: 'success'
            }, { root: true })
            dispatch('getList', { id: payload.id })
            dispatch('getAllList')
        }
    },
    async saveList({ dispatch, rootState }, payload) {
        const { data } = await http.post(`/user/add`, payload)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('save.success')}!`,
                color: 'success'
            }, { root: true })
            dispatch('getAllList')
        }
    },
    async deleteList({ dispatch, rootState }, payload) {
        const { data } = await http.post(`/user/delete/${payload}`)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('deleteMsg.success')}!`,
                color: 'success'
            }, { root: true })
            dispatch('getAllList')
        }
    },
    async getOrganizationList({ commit }) {
        const { data } = await http.get('/organization/query')
        commit('setOrganizationList', data.data)
    },
    async resetPassword({ commit, dispatch, rootState }, payload) {
        const { data } = await http.post(`/user/reset_password/${payload}`)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('save.setPasswordMsg')}`,
                color: 'success'
            }, { root: true })
            commit('setIsReset', true)
        }
    },
    async changeStatus({ commit, dispatch }, payload) {
        const { data } = await http.post(`/user/isadmin/${payload.id}`, { isadmin: payload.status })
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('update.fail')}!`,
                color: 'error'
            }, { root: true })
        } else {
            if (payload.status === true) {
                dispatch('snackbar/open_snackbar', {
                    msg: `${i18n.t('save.setAdmin')}`,
                    color: 'success'
                }, { root: true })
            } else {
                dispatch('snackbar/open_snackbar', {
                    msg: `${i18n.t('save.cancelAdmin')}`,
                    color: 'success'
                }, { root: true })
            }
        }
        if(payload.isUserHome || payload.isUserHome === true){
            dispatch('getAllList')
        }else{
            console.log('hi')
            dispatch('getAllList')
            dispatch('getList', { id: payload.id })
        }
    },
    async history({ commit, dispatch, rootState }, payload) {
        const { data } = await http.get(`/user/history/${payload}`)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            commit('setHistory', data.data)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};