import { http } from '@/utils/http'
import i18n from '@/i18n'
import router from '@/router';

const state = {
    allData: [],
    data: {
        name: '',
        comment: '',
        contact_email: '',
        contact_name: '',
        status: '',
        zip: [
            {
                name: '',
                comment: '',
                createDT: '',
                name_original: ''
            }
        ],
        message: [
            {
                id: '',
                message: '',
                createDT: '',
                owner: '',
                _owner: {
                    name: '',
                    username: ''
                }
            }
        ],
        model: [
            {
                id: '',
                comment: '',
                version: '',
                createDT: '',
                name: '',
                name_original: ''
            }
        ],
        _createDT: ''
    },
    zipData: {
        id: '',
        name: '',
        comment: '',
        createDT: '',
        name_file: '',
        name_original: ''
    },
    modelData: {
        id: '',
        name: '',
        version: '',
        comment: '',
        createDT: '',
        name_file: '',
        name_original: ''
    },
    newest_data: {},
    newest_model: {},
    loading: false,
    status: null
};

const getters = {
    sortAllData(state) {
        let sort = state.allData.sort((a, b) => {
            let t1 = new Date(a._createDT).getTime()
            let t2 = new Date(b._createDT).getTime()
            return t2 - t1
        })
        return sort
    },
    newestData(state) {
        if (state.data.zip !== null) {
            return state.newest_data = state.data.zip[state.data.zip.length - 1]
        }
    },
    newestModel(state) {
        if (state.data.model !== null) {
            return state.newest_model = state.data.model[state.data.model.length - 1]
        }
    },
};

const mutations = {
    setAllList(state, payload) {
        payload.forEach((item) => {
            if (item.status === '0') {
                item.statusMsg = i18n.t('project.status.processing')
            } else if (item.status === '1') {
                item.statusMsg = i18n.t('project.status.review')
            } else if (item.status === '2') {
                item.statusMsg = i18n.t('project.status.modelReady')
            } else if (item.status === '3') {
                item.statusMsg = i18n.t('project.status.archived')
            } else if (item.status === '4') {
                item.statusMsg = i18n.t('project.status.canceled')
            }
        });
        payload.forEach((item) => {
            if (item.model) {
                item.latestVersion = item.model[item.model.length - 1].version
            } else {
                item.latestVersion = ''
            }
        });
        state.allData = payload
    },
    setList(state, payload) {
        state.data = payload
    },
    setZipData(state, payload) {
        state.zipData = payload
    },
    setModelData(state, payload) {
        state.modelData = payload
    },
    setLoading(state, payload) {
        state.loading = payload
    },
    setStatus(state, payload) {
        state.status = payload
    }
};

const actions = {
    async search({ commit }, payload) {
        let params = '?'
        if (payload.type === 'name') {
            params += `name=${payload.val}`
        } else if (payload.type === '_organization.name') {
            params += `organization=${payload.val}`
        } else if (payload.type === 'status') {
            params += `status=${payload.val}`
        }
        const { data } = await http.get(`/project/query${params}`)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('search.msgError')}!`,
                color: 'error'
            }, { root: true })
        } else {
            commit('setAllList', data.data)
        }
    },
    async getAllList({ commit }) {
        const { data } = await http.get('/project/query')
        commit('setAllList', data.data)
    },
    async getList({ commit, dispatch, rootState }, payload) {
        const { data } = await http.get(`/project/query/${payload.id}`)
        commit('setLoading', true)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            commit('setList', data.data)
            router.push({ name: "projectEdit", params: { id: payload.id } }).catch(() => { });
            commit('setLoading', false)
        }
    },
    async projectZip({ commit, dispatch, rootState }, payload) {
        const { data } = await http.get(`/project/query/${payload.id}/zip/${payload.zip}`)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            commit('setZipData', data.data)
        }
    },
    async projectModel({ commit, dispatch, rootState }, payload) {
        const { data } = await http.get(`/project/query/${payload.id}/model/${payload.model}`)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            commit('setModelData', data.data)
        }
    },
    async saveList({ dispatch, rootState }, payload) {
        const { data } = await http.post(`/project/add`, payload)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('save.success')}!`,
                color: 'success'
            }, { root: true })
            dispatch('getAllList')
        }
    },
    async message({ dispatch }, payload) {
        const { data } = await http.post(`/project/message/${payload.id}`, { message: payload.message })
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('project.msg.messageSuccess')}`,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('project.msg.messageCancle')}`,
                color: 'success'
            }, { root: true })
            dispatch('getList', { id: payload.id })
        }
    },
    async updateStatus({ dispatch, commit }, payload) {
        const { data } = await http.post(`/project/update_status/${payload.id}`, { status: payload.status })
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('project.msg.statusMsgFail')}`,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('project.msg.statusMsgSuccess')}`,
                color: 'success'
            }, { root: true })
            // dispatch('getList', {id: payload.id})
            commit('setStatus', payload.status)
            dispatch('getAllList')
        }
    },
    async updateProject({ dispatch, rootState }, payload) {
        const { data } = await http.post(`/project/update/${payload.id}`, payload.data)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('project.msg.uploadSuccess')}`,
                color: 'success'
            }, { root: true })
            dispatch('getList', { id: payload.id })
        }
    },
    async updateModel({ dispatch, rootState }, payload) {
        const { data } = await http.post(`/project/update_model/${payload.id}`, payload.data)
        if (data.status === false) {
            dispatch('snackbar/open_snackbar', {
                msg: rootState.isEnglish === true ? data.msg_en : data.msg,
                color: 'error'
            }, { root: true })
        } else {
            dispatch('snackbar/open_snackbar', {
                msg: `${i18n.t('project.msg.uploadSuccess')}`,
                color: 'success'
            }, { root: true })
            dispatch('getList', { id: payload.id })
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};