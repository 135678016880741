import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/front',
    component: () => import('../views/front/FrontView.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/front/ProjectView.vue'),
      },
      {
        path: 'home/:id',
        name: 'projectEdit',
        component: () => import('../views/front/ProjectEdit.vue'),
      },
      {
        path: 'organization',
        name: 'organization',
        component: () => import('../views/front/OrganizationView.vue'),
      },
      {
        path: 'organization/:id',
        name: 'organizationDetail',
        component: () => import('../views/front/OrganizationEdit.vue'),
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('../views/front/UserView.vue'),
      },
      {
        path: 'user/:id',
        name: 'userDetail',
        component: () => import('../views/front/UserEdit.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
